import styles from './ProductPrice.module.scss';
import { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'components/primitives/grid';
import { Placeholder } from 'components/primitives/placeholders';
import { renderHTML } from 'utils/render';
import { useSanaTexts } from 'components/sanaText';
import { getFormatPrice } from 'utils/format';
import { usePriceModel } from 'utils/product';
import { useCurrencyInfo } from 'utils/hooks';
import ShowPricePresentation from './ShowPricePresentation';
import { useSelector } from 'react-redux';

const ProductPrice = ({ salesPrice, basePrice, hidePriceLables, priceText, isPoductDetailPage }) => {

  //TICKET 147361 - 3.9 Show/hide sales price in the webshop (134939 & 134942)
  const canChangePricePresentation = useSelector(state => state.user.canChangePricePresentation);
  const currencyInfo = useCurrencyInfo();
  const { price } = usePriceModel(salesPrice, basePrice);
  const formatAsPrice = currencyInfo && getFormatPrice(currencyInfo);

  const formattedPrice = useMemo(() => {
    if (!currencyInfo)
      return null;

    return renderHTML(formatPrice(formatAsPrice(price), currencyInfo));
  }, [currencyInfo, price]);

  const { texts: [
    priceLbl,
  ], loaded } = useSanaTexts([
    'Price',
  ]);

  if (!loaded || formattedPrice == null)
    return <Placeholder className="price-block product-price-placeholder" />;

  return (
    <>
      <Col xs="auto" className="price-block" title={priceLbl}>
        {/* TICKET 147361 - 3.9 Show/hide sales price in the webshop (134939 & 134942) */}
        {
          canChangePricePresentation ? (
          <ShowPricePresentation
            basePrice={basePrice}
            salesPrice={salesPrice}
            currencyInfo={currencyInfo}
            formatPrice={formatPrice}
            priceLbl={priceLbl}
            price={price}
            formatAsPrice={formatAsPrice}
            hidePriceLables={hidePriceLables}
            isPoductDetailPage={isPoductDetailPage}
            priceText={priceText}
            />
        ) : (
              <div className="actual-price-single-block">
            <i className="visually-hidden">{priceLbl} {currencyInfo.symbol + price}</i>
            <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
              {formattedPrice}
            </span>
            {/*TICKET 163794*/}
            {isPoductDetailPage && priceText}
            </div>

        )}
      </Col>
    </>
  );
};

ProductPrice.propTypes = {
  salesPrice: PropTypes.number,
  basePrice: PropTypes.number,
  priceText: PropTypes.any,
  hidePriceLables: PropTypes.bool,
  isPoductDetailPage: PropTypes.bool,
};

export default memo(props => {
  if (props.salesPrice === null)
    return null;

  if (typeof props.salesPrice === 'undefined')
    return <Placeholder className="price-block product-price-placeholder" />;

  return <ProductPrice {...props} />;
});

function formatPrice(price, currencyInfo) {
  if (currencyInfo.decimalDigits === 0)
    return price;

  return price.replace(/(\d+)([^\d]*)$/, '<sup>$1</sup>$2');
}
