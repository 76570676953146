//TICKET 147361 - 3.9 Show/hide sales price in the webshop (134939 & 134942)
import styles from './ProductPrice.module.scss';
import { SimpleText } from 'components/sanaText';
import { renderHTML } from 'utils/render';
import { useSelector } from 'react-redux';

const ShowPricePresentation = ({
  salesPrice,
  basePrice,
  currencyInfo,
  formatPrice,
  priceLbl,
  price,
  formatAsPrice,
  hidePriceLables,
  isPoductDetailPage,
  priceText, //TICKET 163794
}) => {
  const showBothPrices = useSelector(state => state.user.showBothPrices);

  const formatPriceConfiguration = price => {
    if (!currencyInfo)
      return null;

    return renderHTML(formatPrice(formatAsPrice(price), currencyInfo));
  };

  if (showBothPrices) {
    return (
      <>
      <div className="actual-price-block">
        {!hidePriceLables && <span className="actual-pricelbl"><SimpleText textKey="PricePresentation_Price" /></span>}
        <i className="visually-hidden">{priceLbl} {currencyInfo.symbol + price}</i>
        <span className={`${styles.actualPrices} actual-prices`} aria-hidden suppressHydrationWarning>
          {formatPriceConfiguration(salesPrice)}
        </span>

        {isPoductDetailPage && priceText}
        </div>
              <br />
       <div className="retail-price-block">
        {!hidePriceLables && <span className="retail-pricelbl"><SimpleText textKey="PricePresentation_Retailprice" /></span>}
        <i className="visually-hidden">
          {priceLbl} {currencyInfo.symbol + price}
        </i>
        <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
          {formatPriceConfiguration(basePrice)}
        </span>
        {isPoductDetailPage && priceText}
        </div>
        </>
    );
  } else {
    return (
      <div className="price-area">
        {!hidePriceLables && <span className="pricebase-label"><SimpleText textKey="PricePresentation_Retailprice" /></span>}
        <i className="visually-hidden">
          {priceLbl} {currencyInfo.symbolSymbol + price}
        </i>
        <span className={`${styles.actualPrice} actual-price`} aria-hidden suppressHydrationWarning>
          {formatPriceConfiguration(basePrice)}
        </span>
        {isPoductDetailPage && priceText}
      </div>
    );
  }
};

export default ShowPricePresentation;
